// src/pages/Activities.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import image1 from '../../../assets/15846.jpg';
import image2 from '../../../assets/2148913227.jpg';
import { FaEdit, FaTrash, FaSearch, FaPlus, FaTh, FaTable } from 'react-icons/fa';

export default function Activities() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableView, setIsTableView] = useState(true);
  const [activitiesData, setActivitiesData] = useState([
    { id: 1, title: 'Activité de Formation', description: 'Formation pour améliorer les compétences techniques.', objectif: 'Améliorer les compétences techniques.', image: image1 },
    { id: 2, title: 'Atelier d\'écriture', description: 'Atelier pour développer des compétences en écriture créative.', objectif: 'Encourager la créativité.', image: image2 },
  ]);
  const [editData, setEditData] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [objectif, setObjectif] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setEditData(null);
    setTitle('');
    setDescription('');
    setObjectif('');
    setImagePreview(null);
  };

  const handleCreateOrEditActivity = (e) => {
    e.preventDefault();
    const newActivity = {
      id: editData ? editData.id : activitiesData.length + 1,
      title,
      description,
      objectif,
      image: imagePreview || (editData && editData.image),
    };

    if (editData) {
      setActivitiesData(activitiesData.map((activity) => (activity.id === editData.id ? newActivity : activity)));
    } else {
      setActivitiesData([...activitiesData, newActivity]);
    }

    toggleModal();
  };

  const handleEdit = (activity) => {
    setEditData(activity);
    setTitle(activity.title);
    setDescription(activity.description);
    setObjectif(activity.objectif);
    setImagePreview(activity.image);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setActivitiesData(activitiesData.filter((activity) => activity.id !== id));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredActivities = activitiesData.filter(
    (activity) =>
      activity.title.toLowerCase().includes(searchQuery) ||
      activity.description.toLowerCase().includes(searchQuery) ||
      activity.objectif.toLowerCase().includes(searchQuery)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredActivities.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredActivities.length / itemsPerPage);

  return (
    <div className="p-0 bg-white rounded-lg w-full  mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <h1 className="text-2xl font-bold mb-2 md:mb-0">Activités</h1>
      </div>

      {/* Search Bar and Toggle View + Create Button Group */}
      <div className="flex items-center justify-between mb-4 w-full">
        <div className="flex items-center w-full max-w-md">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Rechercher une activité..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full px-3 py-2 border rounded-lg outline-none"
          />
        </div>

        {/* Toggle View and Create Button Group */}
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsTableView(true)}
            className={`flex items-center px-2 py-2 rounded-full transition duration-200 ${
              isTableView ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            <FaTable />
          </button>
          <button
            onClick={() => setIsTableView(false)}
            className={`flex items-center px-2 py-2 rounded-full transition duration-200 ${
              !isTableView ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            <FaTh />
          </button>
          <button
            onClick={toggleModal}
            className="bg-red-600 text-white px-4 py-2 rounded-lg shadow hidden sm:inline-block hover:bg-red-700"
          >
            <FaPlus className="inline mr-2" /> Créer
          </button>
        </div>
      </div>

      {/* Modal Popup for Creating/Editing Activities */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-xs sm:max-w-md lg:max-w-lg mx-auto overflow-y-auto" style={{ maxHeight: '80vh' }}>
            <h2 className="text-xl font-bold mb-4 text-center">{editData ? 'Modifier' : 'Créer'} une Activité</h2>
            <form onSubmit={handleCreateOrEditActivity}>
              <div className="flex flex-col space-y-4">
                <div>
                  <label className="block text-gray-700 mb-2">Titre</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Objectif</label>
                  <input
                    type="text"
                    value={objectif}
                    onChange={(e) => setObjectif(e.target.value)}
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Description</label>
                  <ReactQuill
                    value={description}
                    onChange={setDescription}
                    className="bg-white h-32 md:h-36 mb-20"
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        [{ 'font': [] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'align': [] }],
                        ['link', 'image'],
                        ['clean'],
                      ],
                    }}
                    placeholder="Entrez la description..."
                  />
                </div>

                {/* Image Upload */}
                <div>
                  <label className="block text-gray-700 mb-2">Image</label>
                  <div className="flex flex-col items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer hover:border-blue-500 transition">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                      id="fileInput"
                    />
                    <label htmlFor="fileInput" className="flex flex-col items-center justify-center h-full w-full">
                      {imagePreview ? (
                        <img src={imagePreview} alt="Preview" className="w-full h-full object-cover rounded-lg" />
                      ) : (
                        <div className="text-center">
                          <svg className="w-10 h-10 mx-auto text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm3 14H9a1 1 0 010-2h6a1 1 0 010 2zm-2-4H9a1 1 0 010-2h4a1 1 0 010 2zm4-4H9a1 1 0 010-2h8a1 1 0 010 2z" />
                          </svg>
                          <p className="mt-1 text-blue-500">Déposez votre image ici, ou <span className="text-blue-700 underline">parcourez</span></p>
                          <p className="text-gray-400">Formats : JPG, PNG</p>
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-6 space-x-2">
                <button type="button" onClick={toggleModal} className="px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400">
                  Annuler
                </button>
                <button type="submit" className="px-3 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                  {editData ? 'Enregistrer' : 'Créer'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Toggleable Table or Card View */}
      {isTableView ? (
        <div className="overflow-x-auto w-full shadow-md rounded-lg mt-4 mx-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg text-xs md:text-sm text-center sm:text-left">
            <thead>
              <tr className="bg-gray-100 text-gray-600 uppercase leading-normal">
                <th className="py-3 px-4">ID</th>
                <th className="py-3 px-4">Titre</th>
                <th className="py-3 px-4">Objectif</th>
                <th className="py-3 px-4 hidden md:table-cell">Description</th>
                <th className="py-3 px-4 hidden sm:table-cell">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {currentItems.map((activity) => (
                <tr key={activity.id} className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-3 px-4">{activity.id}</td>
                  <td className="py-3 px-4">{activity.title}</td>
                  <td className="py-3 px-4">{activity.objectif.slice(0, 50)}{activity.objectif.length > 50 ? '...' : ''}</td>
                  <td className="py-3 px-4 hidden md:table-cell">{activity.description.slice(0, 50)}{activity.description.length > 50 ? '...' : ''}</td>
                  <td className="py-3 px-4 flex space-x-2 text-lg md:text-base justify-center sm:justify-start">
                    <button onClick={() => handleEdit(activity)} className="text-blue-500 hover:text-blue-700">
                      <FaEdit />
                    </button>
                    <button onClick={() => handleDelete(activity.id)} className="text-red-500 hover:text-red-700">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          {currentItems.map((activity) => (
            <div key={activity.id} className="bg-white border rounded-lg p-4 shadow-md">
              <img src={activity.image} alt="Activité" className="w-full h-40 object-cover rounded-lg mb-2" />
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-lg font-semibold">{activity.title}</h2>
                <div className="flex space-x-2 text-lg md:text-base">
                  <button onClick={() => handleEdit(activity)} className="text-blue-500 hover:text-blue-700">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(activity.id)} className="text-red-500 hover:text-red-700">
                    <FaTrash />
                  </button>
                </div>
              </div>
              <p className="text-gray-600 mb-1"><strong>Objectif:</strong> {activity.objectif.slice(0, 50)}{activity.objectif.length > 50 ? '...' : ''}</p>
              <p className="text-gray-600 mb-2"><strong>Description:</strong> {activity.description.slice(0, 50)}{activity.description.length > 50 ? '...' : ''}</p>
            </div>
          ))}
        </div>
      )}

      {/* Pagination and Floating "Create" Button */}
      <div className="flex items-center justify-center md:justify-end mt-4 space-x-2">
        {/* Pagination */}
        {/* (Pagination code remains the same) */}
      </div>

      {/* Floating Create Button for Mobile */}
      <button
        onClick={toggleModal}
        className="sm:hidden fixed bottom-6 right-6 bg-red-600 text-white p-4 rounded-full shadow-lg hover:bg-red-700"
      >
        <FaPlus />
      </button>
    </div>
  );
}
