// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';

function Layout() {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      {/* Sidebar Component */}
      <Sidebar />

      {/* Main content area */}
      <div className="flex-1 ml-[20.5%] flex flex-col">
        {/* Navbar Component */}
        <Navbar />
        
        {/* Main content area where nested route content will appear */}
        <main className="flex-1 px-6 py-5 bg-gray-100">
          {/* Render the active nested route here */}
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
