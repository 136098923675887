// src/components/Sidebar.js
import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiBook,
  FiFileText,
  FiCalendar,
  FiFlag,
  FiMessageSquare,
  FiSettings,
  FiUsers,
  FiBriefcase,
  FiLogOut,
} from 'react-icons/fi';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import logo from '../../../assets/1x/icone blanc.png';

function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const menuItems = [
    { title: 'Tableau de Bord', icon: <FiHome size={20}/>, path: '/Admin' },
    { title: 'CCCA Revue', icon: <FiBook size={20}/>, path: '/Admin/ccca-revue' },
    { title: 'Actualité', icon: <FiFileText size={20}/>, path: '/Admin/news' },
    { title: 'Événements', icon: <FiCalendar size={20} />, path: '/Admin/events' },
    { title: 'Activité', icon: <FiFlag size={20}/>, path: '/Admin/activities' },
    { title: 'Services', icon: <FiBriefcase size={20} />, path: '/Admin/Services' },
    { title: 'Conseil d\'administration', icon: <FiUsers size={20} />, path: '/Admin/ConseilAdministration' },

  ];

  const accountItems = [
   
    { title: 'Paramètres', icon: <FiSettings /> },
    { title: 'Déconnexion', icon: <FiLogOut /> },
  ];

  return (
    <>
      {/* Sidebar Drawer for Mobile */}
      <div
        className={`fixed h-[100vh] left-0 w-[20.5%] bg-primary shadow-sm transform transition-transform duration-300`}
      >
        {/* Sidebar Header */}
        <div className="flex items-center justify-between py-[1.14rem] px-4">
          <div className="flex items-center justify-center gap-2 w-full">
            <img src={logo} alt="Logo" className="w-9 h-9" />
            <div className='flex flex-col font-semibold text-[.95rem] text-white leading-[1.15rem]'>
              <span>Chambre de Commerce</span>
              <span>Canada Afrique</span>
              </div>
          </div>
        </div>
        {/* Sidebar Menu */}
        <div className="px-4 py-3">
          <div className="sidebare-admin">
            {menuItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className={`flex items-center gap-4 w-full px-5 py-4 text-gray-700 font-medium transition-all duration-200 hover:bg-secondary hover:text-secondary hover:bg-opacity-10 rounded-md`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <span className="text-xl">{item.icon}</span>
                <span className="">{item.title}</span>
              </NavLink>
            ))}
          </div>

          {/* Account Section */}
          <p className={`text-xs font-semibold px-4 mt-4`}>COMPTE</p>
          <div className="mt-2 space-y-1">
            {accountItems.map((item, index) => (
              <button
                key={index}
                className={`flex items-center gap-3 w-full px-4 py-4 text-gray-700 transition-all duration-200 hover:bg-gray-200 rounded-md`}
              >
                <span className="text-xl">{item.icon}</span>
                <span className="">{item.title}</span>
              </button>
            ))}
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Sidebar;
