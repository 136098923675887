// Dashboard.js
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const localizer = momentLocalizer(moment);


export default function Dashboard() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([
    {
      title: 'Meeting with John',
      start: new Date(2024, 10, 10, 10, 0), // 10 novembre 2024 à 10h
      end: new Date(2024, 10, 10, 12, 0), // 10 novembre 2024 à 12h
    },
    {
      title: 'Lunch with Sarah',
      start: new Date(2024, 10, 11, 13, 0), // 11 novembre 2024 à 13h
      end: new Date(2024, 10, 11, 14, 0), // 11 novembre 2024 à 14h
    },
  ]);
  const handleEventClick = (event) => {
    alert(`Event clicked: ${event.title}`);
  };
  return (
    // <div className="flex flex-col flex-1 bg-gray-100">
    <> 
    <h2 className="m-0 text-[1.5rem] font-semibold text-primary mb-4">Dashboard</h2>
    <div className="flex w-full flex-col gap-6 overflow-y-auto">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {/* Cards for metrics in French */}
        <div className="p-4 bg-white shadow rounded-lg">
          <h3 className="text-lg font-semibold">Dépensé ce mois-ci</h3>
          <p className="text-xl mt-2">682,5 €</p>
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h3 className="text-lg font-semibold">Nouveaux clients</h3>
          <p className="text-xl mt-2">321</p>
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h3 className="text-lg font-semibold">Revenus</h3>
          <p className="text-xl mt-2">350,40 €</p>
        </div>
        <div className="p-4 bg-white shadow rounded-lg">
          <h3 className="text-lg font-semibold">Activité</h3>
          <p className="text-xl mt-2">540,50 €</p>
        </div>
      </div>

      <div className="flex w-full  max-md:flex-col gap-6">
        <div className="w-[60%] max-md:w-full bg-white p-4 shadow rounded-md">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{width:'100%',height:380}}
            onSelectEvent={handleEventClick}
            date={currentDate}
            onNavigate={(date) => setCurrentDate(date)}
            popup
            views={['month','week','day','agenda']}
          />
        </div> 
        <div className="w-[40%] max-md:w-full py-10 bg-secondary bg-opacity-40 px-8 rounded-md shadow">
            test
        </div>
      </div>
    </div>
    </>
    // </div>
  );
}
