// src/components/Navbar.js
import React from 'react';
import { FaBell } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import profile from '../../../assets/1x/tania.jpg';

function Navbar({ toggleSidebar }) {
  return (
    <nav className="w-full  bg-green-600 shadow-md flex items-center justify-between px-6 py-3.5">
      {/* <header className="flex items-center justify-between h-16 px-6 bg-white shadow-md"> */}
        <input
          className="bg-gray-100 rounded-md pl-4 w-[35%] pr-4 py-2 focus:outline-none"
          type="text"
          placeholder="Rechercher"
        />

        <div className='flex items-center gap-3'>
            <div className='relative w-[4Opx] h-[4Opx] rounded-[50px]'>
              <img src={profile} alt='#' className='absolute w-full h-full object-cover' />
            </div>
            <div className='flex flex-col'>
              <span className='text-[1rem] font-semibold'>Franchise It</span>
              <span className='text-[.85rem]'>Admin</span>
            </div>
        </div>
      {/* </header> */}
    </nav>
  );
}

export default Navbar;
