// src/pages/CccaRevue.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaEdit, FaTrash, FaPlus, FaTable, FaTh, FaSearch, FaCalendarAlt } from 'react-icons/fa';

const quillModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }, { 'direction': 'rtl' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

export default function CccaRevue() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [isTableView, setIsTableView] = useState(true);

  const [revueData, setRevueData] = useState([
    { id: 1, title: 'Article 1', description: 'Résumé de l\'article 1.', articleNumber: '001', date: '2023-10-01' },
    { id: 2, title: 'Article 2', description: 'Résumé de l\'article 2.', articleNumber: '002', date: '2023-10-02' },
    { id: 3, title: 'Article 3', description: 'Résumé de l\'article 3.', articleNumber: '003', date: '2023-10-03' },
    { id: 4, title: 'Article 4', description: 'Résumé de l\'article 4.', articleNumber: '004', date: '2023-10-04' },
    { id: 5, title: 'Article 5', description: 'Résumé de l\'article 5.', articleNumber: '005', date: '2023-10-05' },
    { id: 6, title: 'Article 6', description: 'Résumé de l\'article 6.', articleNumber: '006', date: '2023-10-06' },
    { id: 7, title: 'Article 7', description: 'Résumé de l\'article 7.', articleNumber: '007', date: '2023-10-07' },
    { id: 8, title: 'Article 8', description: 'Résumé de l\'article 8.', articleNumber: '008', date: '2023-10-08' },
    { id: 9, title: 'Article 9', description: 'Résumé de l\'article 9.', articleNumber: '009', date: '2023-10-09' },
    { id: 10, title: 'Article 10', description: 'Résumé de l\'article 10.', articleNumber: '010', date: '2023-10-10' },
    { id: 11, title: 'Article 11', description: 'Résumé de l\'article 11.', articleNumber: '011', date: '2023-10-11' },
    { id: 12, title: 'Article 12', description: 'Résumé de l\'article 12.', articleNumber: '012', date: '2023-10-12' },
  ]);

  const [editData, setEditData] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setEditData(null);
    setDescription('');
  };

  const handleCreateOrEditRevue = (event) => {
    event.preventDefault();
    const title = event.target.title.value;
    const articleNumber = event.target.articleNumber.value;

    const newRevueItem = {
      id: editData ? editData.id : revueData.length + 1,
      title,
      description,
      articleNumber,
      date: new Date().toISOString().split('T')[0],
    };

    if (editData) {
      setRevueData(revueData.map((item) => (item.id === editData.id ? newRevueItem : item)));
    } else {
      setRevueData([...revueData, newRevueItem]);
    }

    setIsModalOpen(false);
  };

  const handleEdit = (item) => {
    setEditData(item);
    setDescription(item.description);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setRevueData(revueData.filter((item) => item.id !== id));
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = revueData.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!dateFilter || item.date === dateFilter)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div className="flex flex-col items-center p-4 md:p-6 bg-white rounded-lg w-full max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row md:items-center justify-between w-full mb-4">
        <h1 className="text-2xl font-bold text-gray-800">CCCA Revue</h1>

        {/* Toggle & Create Buttons */}
        <div className="flex items-center space-x-2 md:space-x-4 mt-2 md:mt-0">
          <button
            onClick={() => setIsTableView(true)}
            className={`p-2 ${isTableView ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-600'} rounded-full hover:bg-red-700`}
          >
            <FaTable />
          </button>
          <button
            onClick={() => setIsTableView(false)}
            className={`p-2 ${!isTableView ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-600'} rounded-full hover:bg-red-700`}
          >
            <FaTh />
          </button>

          {/* Create button for desktop */}
          <button
            onClick={toggleModal}
            className="hidden md:inline-flex px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            <FaPlus className="mr-2" /> Créer
          </button>
        </div>
      </div>

      {/* Search and Date Filter */}
      <div className="flex flex-col sm:flex-row items-center justify-between w-full mb-4 gap-2">
        <div className="flex items-center border rounded-md px-3 py-2 w-full sm:w-1/2 max-w-md">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Rechercher par titre..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full bg-transparent outline-none text-sm"
          />
        </div>
        <div className="flex items-center border rounded-md px-3 py-2 w-full sm:w-1/2 max-w-sm">
          <FaCalendarAlt className="text-gray-500 mr-2" />
          <input
            type="date"
            value={dateFilter}
            onChange={handleDateFilterChange}
            className="w-full bg-transparent outline-none text-sm"
          />
        </div>
      </div>

      {/* Floating Create Button for Mobile */}
      <button
        onClick={toggleModal}
        className="fixed bottom-6 right-6 bg-red-600 text-white p-4 rounded-full shadow-lg md:hidden"
      >
        <FaPlus />
      </button>

      {/* Modal Popup for Creating/Editing Revue */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg md:max-w-3xl mx-auto overflow-auto" style={{ maxHeight: '90vh' }}>
            <h2 className="text-xl font-bold mb-4 text-center">{editData ? 'Modifier' : 'Créer'} une Revue</h2>
            <form onSubmit={handleCreateOrEditRevue}>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-gray-700 mb-2">Titre</label>
                  <input
                    type="text"
                    name="title"
                    defaultValue={editData?.title || ''}
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Numéro de l'Article</label>
                  <input
                    type="text"
                    name="articleNumber"
                    defaultValue={editData?.articleNumber || ''}
                    required
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-red-500"
                  />
                </div>

                {/* Description Field with ReactQuill */}
                <div>
                  <label className="block text-gray-700 mb-2">Description</label>
                  <ReactQuill
                    value={description}
                    onChange={handleDescriptionChange}
                    className="bg-white h-40 max-md:mb-28  mb-16"
                    placeholder="Entrez la description..."
                    modules={quillModules}
                  />
                </div>
              </div>
              <div className="flex justify-end mt-6 space-x-2">
                <button type="button" onClick={toggleModal} className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400">
                  Annuler
                </button>
                <button type="submit" className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                  {editData ? 'Enregistrer' : 'Créer'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Responsive Toggle Views */}
      {isTableView ? (
        <div className="overflow-x-auto w-full mt-4">
          <table className="min-w-full bg-white border border-gray-200 text-center">
            <thead>
              <tr className="bg-gray-100 text-xs md:text-sm">
                <th className="py-2 px-2 border-b text-gray-600 font-bold">ID</th>
                <th className="py-2 px-2 border-b text-gray-600 font-bold">Titre</th>
                <th className="py-2 px-2 border-b text-gray-600 font-bold hidden sm:table-cell">Description</th>
                <th className="py-2 px-2 border-b text-gray-600 font-bold">Numéro</th>
                <th className="py-2 px-2 border-b text-gray-600 font-bold hidden sm:table-cell">Date</th>
                <th className="py-2 px-2 border-b text-gray-600 font-bold">Actions</th>
              </tr>
            </thead>
            <tbody className="text-xs md:text-sm">
              {currentItems.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="py-2 px-2 border-b">{item.id}</td>
                  <td className="py-2 px-2 border-b">{item.title}</td>
                  <td className="py-2 px-2 border-b truncate max-w-xs hidden sm:table-cell">{item.description}</td>
                  <td className="py-2 px-2 border-b">{item.articleNumber}</td>
                  <td className="py-2 px-2 border-b hidden sm:table-cell">{item.date}</td>
                  <td className="py-2 px-2 border-b">
                    <div className="flex justify-center space-x-2">
                      <button onClick={() => handleEdit(item)} className="text-blue-500 hover:text-blue-700">
                        <FaEdit />
                      </button>
                      <button onClick={() => handleDelete(item.id)} className="text-red-500 hover:text-red-700">
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4">
          {currentItems.map((item) => (
            <div key={item.id} className="bg-white shadow-md rounded-lg p-14 text-center lg:max-w-sm mx-auto">
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-sm text-gray-600 mb-3">{item.description}</p>
              <p className="text-xs text-gray-500 mb-1">Numéro: {item.articleNumber}</p>
              <p className="text-xs text-gray-500">Date: {item.date}</p>
              <div className="flex justify-center mt-4 space-x-2">
                <button onClick={() => handleEdit(item)} className="text-blue-500 hover:text-blue-700">
                  <FaEdit />
                </button>
                <button onClick={() => handleDelete(item.id)} className="text-red-500 hover:text-red-700">
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center md:justify-end items-center w-full max-w-6xl mt-4 space-x-1">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
          className="px-3 py-1 bg-gray-200 text-gray-600 rounded-l-md hover:bg-gray-300"
        >
          ← Précédent
        </button>
        {[...Array(totalPages).keys()].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-3 py-1 ${currentPage === index + 1 ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-600'} hover:bg-gray-300`}
          >
            {index + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
          className="px-3 py-1 bg-gray-200 text-gray-600 rounded-r-md hover:bg-gray-300"
        >
          Suivant →
        </button>
      </div>
    </div>
  );
}
